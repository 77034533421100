<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-alarm-multiple"
      title="Wettkämpfe"
      color="primary"
    >
      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <EventTable
        :events="events"
      />
    </base-material-card>
    <base-material-card
      icon="far fa-users"
      title="gemeldete Turner"
      color="primary"
    >
      <v-data-table
        :items="athletes"
        :headers="[
          { text: 'Turner', value: 'person', sortable: false }
        ]"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #item.person="{item}">
          {{ item.person | person }}
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  ... on StbM2021League {
    name
    shortName
    order
    team(team: $team) {
      athletes {
        person { _id givenName familyName }
      }
    }
    events(team: $team) {
      _id
      name
      startDate
      publicComment
      status
      mode
      teams {
        team { _id name }
        home
        result { final score gp tp }
      }
      venue {
        _id
        name
        address { streetAddress streetNumber postalCode addressLocality }
      }
    }
  }
`

export default {
  name: 'start',

  components: {
    EventTable: () => import('../components/EventTable')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    filter: ''
  }),

  computed: {
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...curr.events.map(e => ({
          ...e,
          liga: curr,
          mannschaften: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.team.name) || [],
          ergebnisse: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.result) || []
        })))
        return acc
      }, []).sort((a, b) => {
        if (a.startDate !== b.startDate) return a.startDate < b.startDate ? -1 : 1
        if (a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
      })
    },
    athletes () {
      return this.EventFind.find(e => !!e.team)?.team?.athletes?.slice()?.sort((a, b) => {
        if (a.person.familyName !== b.person.familyName) return a.person.familyName < b.person.familyName ? -1 : 1
        return a.person.givenName < b.person.givenName ? -1 : 1
      })
    }
  },

  methods: {
    open (item) {
      this.$router.push(`/view/${item._id}`)
    },
    openmaps (address) {
      const a = `${address.streetAddress} ${address.streetNumber}, ${address.postalCode}, ${address.addressLocality}`
      window.open(`https://www.google.de/maps/place/${a.replaceAll(' ', '+')}`, '_blank')
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID, $team: UUID!) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id,
          team: this.team
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID, $team: UUID!) { EventUpdate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id,
            team: this.team
          }
        }
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
